<template>
    <Toast :baseZIndex="1" />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <DataTable :value="pincodeList" :lazy="true" :paginator="true" :rows="30" :totalRecords="totalRecords"
                    :loading="loading" @page="onPage($event)" class="p-datatable-users" data-key="map1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Pincode</h5>
                        </template>
                        <template v-slot:right>
                            <Button label="Import" icon="pi pi-paperclip" class="p-mr-2" @click="onImportPincode" />
                            <Button label="Add pincode" icon="pi pi-plus" class="p-mr-2" @click="onAddPincode" />
                            <Button v-if="!showFillFilter" label="Filter" icon="pi pi-filter" @click="onFilter()"></Button>
                            <Button v-if="showFillFilter" label="Filter" icon="pi pi-filter-slash" class="p-button-success"
                                @click="onFilter()"></Button>
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column header="Sr." headerStyle="width: 4%">
                        <template #body="{ index }">
                            <div>{{ genrateSrNo(index) }}</div>
                        </template>
                    </Column>
                    <Column header="State" field="state" headerStyle="width: 25%">
                        <template #body="{ data: { mbi3 } }">
                            <div class="p-text-capitalize">{{ mbi3 || 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="City" field="city" headerStyle="width: 60%">
                        <template #body="{ data: { mbi5 } }">
                            <div class="p-text-capitalize">{{ mbi5 || 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="Pincode" field="pincode" headerStyle="width: 25%">
                        <template #body="{ data: { mbi6 } }">
                            <div class="p-text-capitalize">
                                <span :class="'user-badge status-temp-suspend'">{{ mbi6 || 'N/A' }}</span>
                            </div>
                        </template>
                    </Column>

                    <Column header="Action" headerStyle="width: 10%" class="p-text-center">
                        <template #body="{ data }">
                            <Button icon="pi pi-pencil" class="p-button-rounded " @click="onEditPincode(data)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
    <!-- add pincode dialog start here -->
    <Dialog v-model:visible="isAddPincode" :style="{ width: '800px' }" header="Add pincode" :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="stateId">
                        State
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="stateId" v-model="add.state" :options="stateList" @change="getCities($event)"
                        optionLabel="label" placeholder="Select State" :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.add.state.$error">{{
                        v$.add.state.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="cityId">
                        City
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="cityId" v-model="add.city" :options="cityList" optionLabel="label"
                        placeholder="Select City" :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.add.city.$error">{{
                        v$.add.city.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="pincodeId">
                        Pincode
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="pincodeId" v-model.trim="add.pincode" required="true" maxlength="6" autofocus
                        :class="{ 'p-invalid': isSubmitted && !add.pincode }" />
                    <small class="p-invalid p-error" v-if="v$.add.pincode.$error">{{
                        v$.add.pincode.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label>status <span class="p-invalid p-error"> *</span></label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="status_active" name="option" v-bind:value="1" v-model="add.status"
                            :checked="add.status == 1" />
                        <label for="status_active" class="p-mr-2">Active</label>
                        <RadioButton id="status_Inactive" name="option" v-bind:value="0" v-model="add.status"
                            :checked="add.status == 0" />
                        <label for="status_Inactive" class="p-mr-2">Inactive</label>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button label="Submit" :icon="`pi ${!showLoader ? 'pi-check' : 'pi-spin pi-spinner indigo-color'}`"
                    class="p-button-text p-ml-auto" @click="addPincodeBtn()" style="width: 90px"></Button>
                <!-- <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button> -->
            </div>
        </template>
    </Dialog>
    <!-- add pincode dialog end here -->

    <!-- edit pincode dialog start here -->
    <Dialog v-model:visible="isEditPincode" :style="{ width: '800px' }" header="Edit pincode" :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="state">
                        State
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="state" v-model="edit.state" @change="getCities($event)" :options="stateList"
                        optionLabel="label" placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.edit.state.$error">{{
                        v$.edit.state.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="city-pincode">
                        City
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="city-pincode" v-model="edit.city" :options="cityList" optionLabel="label"
                        placeholder="Select City" :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.edit.city.$error">{{
                        v$.edit.city.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="pincode">
                        pincode
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="pincode" class="p-text-capitalize" v-model.trim="edit.pincode" required="true"
                        @keypress="onlyNumber" maxlength="20" autofocus :class="{ 'p-invalid': isSubmitted && !pincode }" />
                    <small class="p-invalid p-error" v-if="v$.edit.pincode.$error">{{
                        v$.edit.pincode.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label>status <span class="p-invalid p-error"> *</span></label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="status_active" name="option" v-bind:value="1" v-model="edit.status"
                            :checked="edit.status == 1" />
                        <label for="status_active" class="p-mr-2">Active</label>
                        <RadioButton id="status_Inactive" name="option" v-bind:value="0" v-model="edit.status"
                            :checked="edit.status == 0" />
                        <label for="status_Inactive" class="p-mr-2">Inactive</label>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="editPincodeBtn()" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- edit pincode dialog end here -->
    <!----Start of filter--------------------------------->
    <Dialog v-model:visible="isFilterDialog" :style="{ width: '600px' }" header="Filter" :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="state">
                        State
                    </label>
                    <Dropdown id="state" v-model="stateFilter" @change="getCities($event)" :options="stateList"
                        optionLabel="label" placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>

                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="city-pincode">
                        City
                    </label>
                    <Dropdown id="city-pincode" v-model="cityFilter" :options="cityList" optionLabel="label"
                        placeholder="Select City" :filter="true" appendTo="body"></Dropdown>

                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="pincode">
                        pincode
                    </label>
                    <InputText id="pincode" class="p-text-capitalize" v-model.trim="pincodeFilter" required="true"
                        @keypress="onlyNumber" maxlength="20" autofocus :class="{ 'p-invalid': isSubmitted && !pincode }" />
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Reset" icon="pi pi-refresh" class="p-button-text" @click="reSetFilteredData()" />
            <Button label="Search" icon="pi pi-search" class="p-mr-2" autofocus
                @click="getFilteredData(cityFilter, pincodeFilter, areaFilter)" />
        </template>
    </Dialog>
    <!----End of filter--------------------------------->

    <!-- import csv dialog start here -->
    <Dialog v-model:visible="isImportPincode" :style="{ width: '1100px' }" header="Import pincode" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6" align="left">
                    <FileUpload mode="basic" ref="importpincodefile" class="custom-upload-btn-ultima"
                        v-model="importpincodefile" v-on:change="handleUploadImport()" />
                    <div style="color:red" v-if="importmsg.file">
                        {{ importmsg.file }}
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-6" align="right">
                    <label class="p-mr-2">
                        Download Sample Sheet:
                    </label>
                    <!-- TODO: Need CSV File URL here -->
                    <router-link href="https://storage.googleapis.com/meraneta_admin/import_pincode_data.csv" target="_blank" to="#">
                        <Button icon="pi pi-download" v-tooltip.left="'Download Sample Sheet'" class="p-button-rounded">
                        </Button>
                    </router-link>
                </div>
                <div class="p-field p-col-12">
                    <h5 class="indigo-color p-m-0 p-pl-1">Note:</h5>
                    <ol style="line-height: 1.5;">
                        <li>The file name should be same as sample file name "import_pincode_data.csv".</li>
                        <li>Do not just change any files extension from .XLS or any other extension to CSV and upload it.
                        </li>
                        <li>You can upload enquiries in one sheet up to 5000 enquiries in one attempt.</li>
                        <li>Don't change and delete any column name in CSV sheet.</li>
                        <li>Date format must be in "dd-mm-yyyy".</li>
                        <li>Insert employee mobile number properly to allocate the enquiry to a particular employee, else
                            enquiry will be inserted into logged-in employees Lok-neta account.</li>
                        <li>Project name, unit type & source must be the same as in the Lok-neta System.</li>
                        <li>In CSV sheet enquiry date, first name or last name, mobile or email, employee mobile, source
                            column's are mandatory.</li>
                        <li>Submitted sheets for importing pincode data till 10 pm will be executed on the same night and the
                            result will be available by 7 am next day</li>
                    </ol>
                </div>
            </div>
        </div>
        <template #footer>
            <Button v-if="!importfileloader" label="Submit" icon="pi pi-check" class="p-button-text" :disabled="importmsg.file != '' ||
                importpincodefile == null ||
                importpincodefile == ''
                " @click="onCSVSubmit" style="width: 90px" />
            <Button v-show="importfileloader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button>
        </template>
    </Dialog>
    <!-- import dialog end here -->
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
export default {
    data() {
        return {
            v$: useValidate(),
            pincodeList: [],
            page_no: 0,
            totalRecords: 0,
            loading: false,
            isAddPincode: false,
            add: {
                state: '',
                city: '',
                pincode: '',
                status: 1,
            },
            edit: {
                state: '',
                city: '',
                pincode: '',
                status: 1,
            },
            stateList: [],
            cityList: [],
            isImportPincode: false,
            showLoader: false,
            isSubmitted: false,
            row_id: null,
            isFilterDialog: false,
            showFillFilter: false,
            isEditPincode: false,
            importfileloader: false,
            importmsg: [],
            importpincodefile: "",
            cityFilter: '',
            stateFilter: '',
            pincodeFilter: '',
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        this.getPincodeList();
        this.getStates(101);
    },
    validations() {
        return {
            add: {
                state: { required: helpers.withMessage('Please select state', required) },
                city: { required: helpers.withMessage('Please select city', required) },
                pincode: { required: helpers.withMessage('Please enter pincode', required) },
            },
            edit: {
                state: { required: helpers.withMessage('Please select state', required) },
                city: { required: helpers.withMessage('Please select city', required) },
                pincode: { required: helpers.withMessage('Please enter pincode', required) },
            },
        };
    },
    methods: {
        getStates(ev) {
            this.ApiService.getStates(ev).then((data) => {
                if (data.status == 200) {
                    this.stateList = data.data;
                } else {
                    this.stateList = '';
                }
            });
        },
        getCities(ev) {
            this.ApiService.getcities({ state_id: ev.value.value }).then((data) => {
                if (data.success === true) {
                    this.cityList = data.data;
                } else {
                    this.cityList = null;
                }
            });
        },
        handleUploadImport() {
            var file = this.$refs.importpincodefile.files[0];
            let allowedExtensions = /(\.csv)$/i;
            if (!file) {
                this.importmsg["file"] = "Please upload CSV excel file.";
            } else if (!file.size > 2048) {
                this.importmsg["file"] = "File size cannot exceed more than 2MB";
            } else if (!file.name.match(allowedExtensions)) {
                this.importmsg["file"] = "Invalid file type";
            } else {
                this.importmsg["file"] = "";
                this.importpincodefile = this.$refs.importpincodefile.files[0];
            }
        },
        onImportPincode() {
            this.isImportPincode = true;
        },
        onAddPincode() {
            try {
                this.isAddPincode = true;
                this.city = '';
                this.pincode = '';
                this.pincode = '';
                this.showLoader = false;
                setTimeout(() => {
                    this.v$.$reset();
                }, 0);
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
            }
        },
        addPincodeBtn() {
            this.v$.add.$validate();
            let fields = {};
            if (this.add.state.label) {
                fields["mbi2"] = this.add.state.value;
            }
            if (this.add.state.value) {
                fields["mbi3"] = this.add.state.label;
            }
            if (this.add.city.label) {
                fields["mbi4"] = this.add.city.value;
            }
            if (this.add.city.value) {
                fields["mbi5"] = this.add.city.label;
            }
            fields["mbi6"] = this.add.pincode;
            fields["mbi7"] = this.add.status;
            if (!this.v$.$error) {
                this.showLoader = true;
                this.ApiService.addUpdatePincode(fields).then((items) => {
                    if (items.status == 200) {
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.isAddPincode = false;
                        this.showLoader = false;
                        this.add.state = '';
                        this.add.city = '';
                        this.add.pincode = '';
                        this.add.status = 1;
                        this.getPincodeList();
                    } else {
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                        this.showLoader = false;
                    }
                });
            }
        },
        onEditPincode(e) {
            try {
                this.isEditPincode = true;
                this.edit.city = '';
                this.edit.state = '';
                this.edit.pincode = '';
                this.edit.showLoader = false;
                this.row_id = e.mbi1;
                this.edit.pincode = e.mbi6;
                this.edit.status = e.mbi7;
                this.ApiService.getcities({ state_id: e.mbi2 }).then((data) => {
                    if (data.success === true) {
                        this.cityList = data.data;
                    } else {
                        this.cityList = null;
                    }
                });
                this.edit.city = {
                    value: e.mbi4,
                    label: e.mbi5,
                }

                let mbi2 = this.stateList.filter(function (item) {
                    return item.value == e.mbi2;
                });
                if (mbi2.length > 0) {
                    this.edit.state = mbi2[0];
                }
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
            }
        },
        editPincodeBtn() {
            this.v$.edit.$validate();
            let fields = {};
            fields["mbi1"] = this.row_id;
            if (this.edit.state.value) {
                fields["mbi2"] = this.edit.state.value;
            }
            if (this.edit.state.label) {
                fields["mbi3"] = this.edit.state.label;
            }
            if (this.edit.city.value) {
                fields["mbi4"] = this.edit.city.value;
            }
            if (this.edit.city.label) {
                fields["mbi5"] = this.edit.city.label;
            }
            fields["mbi6"] = this.edit.pincode;
            fields["mbi7"] = this.edit.status;
            if (!this.v$.edit.$error) {
                this.showLoader = true;
                this.ApiService.addUpdatePincode(fields).then((items) => {
                    if (items.status == 200) {
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.isEditPincode = false;
                        this.showLoader = false;
                        this.edit.state = '';
                        this.edit.city = '';
                        this.edit.pincode = '';
                        this.edit.status = '';
                        this.getPincodeList();

                    } else {
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                        this.showLoader = false;
                    }
                });
            }
        },
        onCSVSubmit() {
            this.importfileloader = true;
            var formData = new FormData();
            formData.append("importfile", this.importpincodefile);
            this.ApiService.importPincodeData(formData).then((items) => {
                if (items.success == true) {
                    var successMsg = items.message;
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    this.isImportPincode = false;
                    this.getPincodeList();
                    this.importfileloader = false;
                    this.importpincodefile = "";
                } else {
                    var errorMsg = items.message;
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                }
            });
        },
        getPincodeList() {
            this.loading = true;
            this.ApiService.getPincodeList({page_no: this.page_no,
                cityfilter: this.cityFilter,
                statefilter: this.stateFilter,
                pincodefilter: this.pincodeFilter,
            }).then((data) => {
                if (data.status == 200) {
                    this.pincodeList = data.result;
                    this.totalRecords = data.data;
                    this.loading = false;
                } else {
                    this.pincodeList = '';
                    this.loading = false;
                }
                this.loading = false;
            });
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getPincodeList({ page_no: event.page, statefilter: this.event_state, loksabhafilter: this.event_loksabha });
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },

        onFilter() {
            this.isFilterDialog = true;
        },
        getFilteredData() {
            this.page_no = 0;
            this.loading = true;
            // this.cityFilter = event_state;
            // this.loksabha_fks = event_loksabha;
            this.getPincodeList();
            this.showFillFilter = !!(this.cityFilter || this.pincodeFilter || this.pincodeFilter);
            this.isFilterDialog = false;
        },
        reSetFilteredData() {
            this.stateFilter = "";
            this.cityFilter = "";
            this.pincodeFilter = '';
            this.getPincodeList();
            this.showFillFilter = false;
            this.isFilterDialog = false;
        }
    },
};
</script>
<style scoped lang="scss">
.user-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-active {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-per-suspend {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-temp-suspend {
        background: #ffd8b2;
        color: #805b36;
    }
}
</style>
